import { createSlice } from '@reduxjs/toolkit';
import { subMonths } from 'date-fns';

const currentDate = new Date();
// const defaultStartDate = currentDate;
const defaultEndDate = subMonths(currentDate, 12);
const defaultStartDate = subMonths(defaultEndDate, 1);
// const lastYear = currentDate.getFullYear() - 1;

// Set the first date of the current month of the previous year
// const firstDateOfLastYearMonth = new Date(lastYear, currentDate.getMonth(), 1);

const todayDate = new Date();
let oneMonthAgoDate = new Date(todayDate);
oneMonthAgoDate.setMonth(todayDate.getMonth() - 1);

// Set the last date of the current month of the previous year
// const lastDateOfLastYearMonth = new Date(
//   lastYear,
//   currentDate.getMonth() + 1,
//   0,
// );

// For filter popup and Pagination
let initialState = {
  filterLoading: false,
  filterCRUDLoading: false,
  allFilters: {
    salesDashboard: {
      salesReport: {
        dates: {
          startDate: oneMonthAgoDate,
          endDate: todayDate,
          key: 'selection',
        },
      },
      laminationReport: {
        dates: {
          startDate: oneMonthAgoDate,
          endDate: todayDate,
          key: 'selection',
        },
      },
      partyTypeReport: {
        dates: {
          startDate: oneMonthAgoDate,
          endDate: todayDate,
          key: 'selection',
        },
      },
      industryReport: {
        dates: {
          startDate: oneMonthAgoDate,
          endDate: todayDate,
          key: 'selection',
        },
      },
      customerSourceReport: {
        dates: {
          startDate: oneMonthAgoDate,
          endDate: todayDate,
          key: 'selection',
        },
      },
      customerSorceDetailReport: {
        dates: {
          startDate: oneMonthAgoDate,
          endDate: todayDate,
          key: 'selection',
        },
      },
    },

    adminDashboard: {
      partyTypeRatioReport: {
        dates: {
          startDate: oneMonthAgoDate,
          endDate: todayDate,
          key: 'selection',
        },
      },
      laminationRatioReport: {
        dates: {
          startDate: oneMonthAgoDate,
          endDate: todayDate,
          key: 'selection',
        },
      },
      pendingJobPrintTechnologyReport: {
        dates: {
          startDate: oneMonthAgoDate,
          endDate: todayDate,
          key: 'selection',
        },
      },
      pendingJobBagTypeReport: {
        dates: {
          startDate: oneMonthAgoDate,
          endDate: todayDate,
          key: 'selection',
        },
      },
      stateTurnoverReport: {
        dates: {
          startDate: oneMonthAgoDate,
          endDate: todayDate,
          key: 'selection',
        },
      },
    },

    parties: {
      applied: {},
      filters: [],
      currentPage: 1,
      pageLimit: 30,
      transporterPageLimit: 30,
      transporterCurrentPage: 1,
      selectedItemIndex: '',
    },
    rollConsumption: {
      currentPage: 1,
      pageLimit: 30,
    },
    inkConsumption: {
      currentPage: 1,
      pageLimit: 30,
    },
    stockRawMaterial: {
      applied: {},
      filters: [],
      currentPage: 1,
      pageLimit: 30,
      selectedItemIndex: '',
    },
    stockConsumption: {
      applied: {},
      filters: [],
      currentPage: 1,
      pageLimit: 30,
      selectedItemIndex: '',
      // dates: null,
      dates: {
        startDate: '',
        endDate: '',
        key: 'selection',
      },
    },
    stockTransfer: {
      applied: {},
      filters: [],
      currentPage: 1,
      pageLimit: 30,
      selectedItemIndex: '',
      dates: {
        startDate: '',
        endDate: '',
        key: 'selection',
      },
    },
    finance: {
      applied: {},
      filters: [],
      currentPage: 1,
      // mobileCurrentPageLimit: 30,
      // mobileCurrentPage: 1,
      pageLimit: 30,
      selectedItemIndex: '',
    },
    customer: {
      applied: {},
      filters: [],
      currentPage: 1,
      // mobileCurrentPageLimit: 30,
      // mobileCurrentPage: 1,
      pageLimit: 30,
      selectedItemIndex: '',
    },
    brokerMargin: {
      applied: {},
      filters: [],
      currentPage: 1,
      pageLimit: 30,
      pendingBrokerCurrentPage: 1,
      pendingBrokerCurrentPageLimit: 30,
      selectedItemIndex: '',
      dates: [],
    },
    product: {
      applied: {},
      filters: [],
      currentPage: 1,
      // mobileCurrentPage: 1,
      mobileCurrentPageLimit: 30,
      mobileCurrentPage: 1,
      pageLimit: 30,
      selectedItemIndex: '',
    },
    bags: {
      applied: {},
      filters: [],
      currentPage: 1,
      pageLimit: 30,
      selectedItemIndex: '',
    },
    trendingProduct: {
      applied: {},
      filters: [],
      currentPage: 1,
      pageLimit: 30,
      selectedItemIndex: '',
    },
    productTransfer: {
      applied: {},
      filters: [],
      currentPage: 1,
      pageLimit: 30,
      selectedItemIndex: '',
      // dates: null,
      dates: {
        startDate: '',
        endDate: '',
        key: 'selection',
      },
    },
    mobileAppPriceList: {
      applied: {},
      currentPage: 1,
      pageLimit: 30,
    },
    user: {
      applied: {},
      filters: [],
      currentPage: 1,
      pageLimit: 30,
      selectedItemIndex: '',
    },
    itemRawMaterials: {
      applied: {},
      filters: [],
      currentPage: 1,
      pageLimit: 30,
      selectedItemIndex: '',
    },
    rollPermissions: { applied: {}, filters: [] },
    //  miscmasters
    warehouse: {
      applied: {},
      filters: [],
      currentPage: 1,
      pageLimit: 30,
    },
    unit: {
      applied: {},
      filters: [],
      currentPage: 1,
      pageLimit: 30,
    },
    bank: { applied: {}, filters: [], currentPage: 1, pageLimit: 30 },
    bagType: { applied: {}, filters: [], currentPage: 1, pageLimit: 30 },
    bagCollection: { applied: {}, filters: [], currentPage: 1, pageLimit: 30 },
    printingType: { applied: {}, filters: [], currentPage: 1, pageLimit: 30 },
    printingTechnology: {
      applied: {},
      filters: [],
      currentPage: 1,
      pageLimit: 30,
    },
    country: { applied: {}, filters: [], currentPage: 1, pageLimit: 30 },
    state: { applied: {}, filters: [], currentPage: 1, pageLimit: 30 },
    city: { applied: {}, filters: [], currentPage: 1, pageLimit: 30 },
    operatorRole: { applied: {}, filters: [], currentPage: 1, pageLimit: 30 },
    factoryLocation: {
      applied: {},
      filters: [],
      currentPage: 1,
      pageLimit: 30,
    },
    bagMachine: { applied: {}, filters: [], currentPage: 1, pageLimit: 30 },
    laminationType: { applied: {}, filters: [], currentPage: 1, pageLimit: 30 },
    businessType: { applied: {}, filters: [], currentPage: 1, pageLimit: 30 },
    itemGroup: { applied: {}, filters: [], currentPage: 1, pageLimit: 30 },
    partyType: { applied: {}, filters: [], currentPage: 1, pageLimit: 30 },
    market: { applied: {}, filters: [], currentPage: 1, pageLimit: 30 },
    industry: { applied: {}, filters: [], currentPage: 1, pageLimit: 30 },
    customerSource: { applied: {}, filters: [], currentPage: 1, pageLimit: 30 },
    customerSourceDetail: {
      applied: {},
      filters: [],
      currentPage: 1,
      pageLimit: 30,
    },
    patchCylinder: { applied: {}, filters: [], currentPage: 1, pageLimit: 30 },
    customerGroup: { applied: {}, filters: [], currentPage: 1, pageLimit: 30 },
    backgroundDesign: {
      applied: {},
      filters: [],
      currentPage: 1,
      pageLimit: 30,
    },
    handleMaterial: { applied: {}, filters: [], currentPage: 1, pageLimit: 30 },
    bagCapacity: { applied: {}, filters: [], currentPage: 1, pageLimit: 30 },
    form: { applied: {}, filters: [], currentPage: 1, pageLimit: 30 },
    material: { applied: {}, filters: [], currentPage: 1, pageLimit: 30 },
    fabricColor: { applied: {}, filters: [], currentPage: 1, pageLimit: 30 },
    addressType: { applied: {}, filters: [], currentPage: 1, pageLimit: 30 },
    machineType: { applied: {}, filters: [], currentPage: 1, pageLimit: 30 },
    gsmLength: { applied: {}, filters: [], currentPage: 1, pageLimit: 30 },
    machines: { applied: {}, filters: [], currentPage: 1, pageLimit: 30 },
    velcro: {
      applied: {},
      filters: [],
      currentPage: 1,
      pageLimit: 30,
    },
    customerRating: {
      applied: {},
      filters: [],
      currentPage: 1,
      pageLimit: 30,
    },
    // End Miscmasters
    order: {
      applied: {},
      filters: [],
      currentPage: 1,
      pageLimit: 30,
      jobsPageLimit: 30,
      jobsCurrentPage: 1,
      // dates: null,
      dates: {
        startDate: '',
        endDate: '',
        key: 'selection',
      },
      selectedItemIndex: '',
    },
    proforma: {
      applied: {},
      filters: [],
      currentPage: 1,
      pageLimit: 30,
      productDataPageLimit: 50,
      productDataCurrentPage: 1,
      // dates: null,
      dates: {
        startDate: '',
        endDate: '',
        key: 'selection',
      },
      selectedItemIndex: '',
    },
    sales: {
      applied: {},
      filters: [],
      currentPage: 1,
      pageLimit: 30,
      // dates: null,
      dates: {
        startDate: '',
        endDate: '',
        key: 'selection',
      },
      selectedItemIndex: '',
    },
    ppPriceHistory: {
      currentPage: 1,
      pageLimit: 30,
    },
    mfgLive: {
      applied: {},
      filters: [],
      currentPage: 1,
      pageLimit: 30,
      selectedItemIndex: '',
      whatsappData: {},
      dates: {
        startDate: '',
        endDate: '',
        key: 'selection',
      },
    },
    mfgLiveFlexo: {
      print: 0,
      machine: 0,
      print_technology: '',
      activeTabName: 'FLEXO PRINT',
      activeTabIndex: 0,
      mfgListParam: {
        currentPage: 1,
        pageLimit: 30,
        // dates: [],
        dates: {
          startDate: '',
          endDate: '',
          key: 'selection',
        },
        cylinder: 0,
        factory: '',
        completed: 0,
        searchQuery: '',
        applied: {},
        filters: [],
        selectedItemIndex: '',
      },
      mfgListZipperParam: {
        currentPage: 1,
        pageLimit: 30,
        // dates: [],
        dates: {
          startDate: '',
          endDate: '',
          key: 'selection',
        },
        cylinder: 0,
        factory: '',
        completed: 0,
        searchQuery: '',
        applied: {},
        filters: [],
        selectedItemIndex: '',
      },
      mfgListLoopParam: {
        currentPage: 1,
        pageLimit: 30,
        // dates: [],
        dates: {
          startDate: '',
          endDate: '',
          key: 'selection',
        },
        cylinder: 0,
        factory: '',
        completed: 0,
        searchQuery: '',
        applied: {},
        filters: [],
        selectedItemIndex: '',
      },
      mfgListRToRParam: {
        currentPage: 1,
        pageLimit: 30,
        cylinderActiveTabIndex: 0,
        // dates: [],
        dates: {
          startDate: '',
          endDate: '',
          key: 'selection',
        },
        cylinder: 0,
        factory: '',
        completed: 0,
        searchQuery: '',
        applied: {},
        filters: [],
        selectedItemIndex: '',
      },
      mfgListBToBParam: {
        currentPage: 1,
        pageLimit: 30,
        cylinderActiveTabIndex: 0,
        dates: {
          startDate: '',
          endDate: '',
          key: 'selection',
        },
        cylinder: 0,
        factory: '',
        completed: 0,
        searchQuery: '',
        applied: {},
        filters: [],
        selectedItemIndex: '',
      },
      mfgListHotStampingParam: {
        currentPage: 1,
        pageLimit: 30,
        cylinderActiveTabIndex: 0,
        dates: {
          startDate: '',
          endDate: '',
          key: 'selection',
        },
        cylinder: 0,
        factory: '',
        completed: 0,
        searchQuery: '',
        applied: {},
        filters: [],
        selectedItemIndex: '',
      },
      mfgListFlexoParam: {
        currentPage: 1,
        pageLimit: 30,
        dates: {
          startDate: '',
          endDate: '',
          key: 'selection',
        },
        cylinderActiveTabIndex: 0,
        cylinder: 0,
        factory: '',
        completed: 0,
        searchQuery: '',
        applied: {},
        filters: [],
        selectedItemIndex: '',
      },
      mfgListSemiRotoParam: {
        currentPage: 1,
        pageLimit: 30,
        dates: {
          startDate: '',
          endDate: '',
          key: 'selection',
        },
        cylinderActiveTabIndex: 0,
        cylinder: 0,
        factory: '',
        completed: 0,
        searchQuery: '',
        applied: {},
        filters: [],
        selectedItemIndex: '',
      },
      mfgListPlainParam: {
        currentPage: 1,
        pageLimit: 30,
        // dates: [],
        dates: {
          startDate: '',
          endDate: '',
          key: 'selection',
        },
        cylinder: 0,
        factory: '',
        completed: 0,
        searchQuery: '',
        applied: {},
        filters: [],
        selectedItemIndex: '',
      },
      mfgListRotoParam: {
        currentPage: 1,
        pageLimit: 30,
        // dates: [],
        dates: {
          startDate: '',
          endDate: '',
          key: 'selection',
        },
        cylinder: 0,
        factory: '',
        completed: 0,
        searchQuery: '',
        applied: {},
        filters: [],
        selectedItemIndex: '',
      },
    },
    purchaseOrder: {
      applied: {},
      filters: [],
      currentPage: 1,
      pageLimit: 30,
      // dates: [],
      dates: {
        startDate: '',
        endDate: '',
        key: 'selection',
      },
      selectedItemIndex: '',
    },
    purchaseReceive: {
      applied: {},
      filters: [],
      pageLimit: 30,
      currentPage: 1,
      selectedItemIndex: '',
      // dates: [],
      dates: {
        startDate: '',
        endDate: '',
        key: 'selection',
      },
    },
    nlRequirement: {
      applied: {},
      filters: [],
      pageLimit: 30,
      currentPage: 1,
      isStockWithoutOrderPageLimit: 30,
      isStockWithoutOrderCurrentPage: 1,
      selectedItemIndex: '',
      dates: {
        startDate: defaultStartDate,
        endDate: defaultEndDate,
        key: 'selection',
      },
    },
    prePrintedStatus: {
      applied: {},
      filters: [],
      currentPage: 1,
      pageLimit: 30,
      completedPageLimit: 30,
      completedCurrentPage: 1,
      selectedItemIndex: '',
      // dates: [],
      dates: {
        startDate: '',
        endDate: '',
        key: 'selection',
      },
    },
  },
  allCommon: {
    parties: {
      filterToggle: false,
      transporterFilterToggle: false,
      partyFilters: {
        party_type_name: { value: '', matchMode: 'contains' },
        party_name: { value: '', matchMode: 'contains' },
        personal_contact_no: { value: '', matchMode: 'contains' },
        state_name: { value: '', matchMode: 'contains' },
        city_name: { value: '', matchMode: 'contains' },
        present_advisor_name: { value: '', matchMode: 'contains' },
        industry_name: { value: '', matchMode: 'contains' },
        customer_source_name: { value: '', matchMode: 'contains' },
        customer_source_detail_name: { value: '', matchMode: 'contains' },
        gst: { value: '', matchMode: 'contains' },
        bag_rate_list_str: { value: '', matchMode: 'contains' },
        active_status: { value: '', matchMode: 'contains' },
      },
      transporterFilters: {
        party_type_name: { value: '', matchMode: 'contains' },
        party_name: { value: '', matchMode: 'contains' },
        personal_contact_no: { value: '', matchMode: 'contains' },
        state_name: { value: '', matchMode: 'contains' },
        city_name: { value: '', matchMode: 'contains' },
        present_advisor_name: { value: '', matchMode: 'contains' },
        industry_name: { value: '', matchMode: 'contains' },
        customer_source_name: { value: '', matchMode: 'contains' },
        customer_source_detail_name: { value: '', matchMode: 'contains' },
        gst: { value: '', matchMode: 'contains' },
        mobile_registered: { value: '', matchMode: 'contains' },
        bag_rate_list_str: { value: '', matchMode: 'contains' },
        is_active: { value: '', matchMode: 'contains' },
      },
      searchQuery: '',
      isTransporterOnly: false,
    },
    rollConsumption: {
      filterToggle: false,
      rollFilters: {
        lamination_type_name: { value: '', matchMode: 'contains' },
        color: { value: '', matchMode: 'contains' },
        design_name: { value: '', matchMode: 'contains' },
        width: { value: '', matchMode: 'contains' },
        length: { value: '', matchMode: 'contains' },
        gsm: { value: '', matchMode: 'contains' },
        in_stock: { value: '', matchMode: 'contains' },
        pending_order: { value: '', matchMode: 'contains' },
        one_month: { value: '', matchMode: 'contains' },
        three_month: { value: '', matchMode: 'contains' },
        six_month: { value: '', matchMode: 'contains' },
        twelve_month: { value: '', matchMode: 'contains' },
      },
    },
    inkConsumption: {
      filterToggle: false,
      inkFilters: {
        machine_name: { value: '', matchMode: 'contains' },
        color: { value: '', matchMode: 'contains' },
        in_stock: { value: '', matchMode: 'contains' },
        one_month: { value: '', matchMode: 'contains' },
        three_month: { value: '', matchMode: 'contains' },
        six_month: { value: '', matchMode: 'contains' },
        twelve_month: { value: '', matchMode: 'contains' },
      },
    },
    stockRawMaterial: {
      filterToggle: false,
      stockFilters: {
        'item_detail.name': { value: '', matchMode: 'contains' },
        warehouse_name: { value: '', matchMode: 'contains' },
        design_name: { value: '', matchMode: 'contains' },
        id_no: { value: '', matchMode: 'contains' },
        color: { value: '', matchMode: 'contains' },
        lamination: { value: '', matchMode: 'contains' },
        texture: { value: '', matchMode: 'contains' },
        width: { value: '', matchMode: 'contains' },
        length: { value: '', matchMode: 'contains' },
        height: { value: '', matchMode: 'contains' },
        gsm: { value: '', matchMode: 'contains' },
        net_weight: { value: '', matchMode: 'contains' },
        po_number: { value: '', matchMode: 'contains' },
        parent_id: { value: '', matchMode: 'contains' },
      },
      searchQuery: '',
    },
    stockConsumption: {
      filterToggle: false,
      stockConsumptionFilters: {
        item_name: { value: '', matchMode: 'contains' },
        consumption_date: { value: '', matchMode: 'contains' },
        job_no: { value: '', matchMode: 'contains' },
        design_name: { value: '', matchMode: 'contains' },
        lamination: { value: '', matchMode: 'contains' },
        id_no: { value: '', matchMode: 'contains' },
        color: { value: '', matchMode: 'contains' },
        width: { value: '', matchMode: 'contains' },
        width_mm: { value: '', matchMode: 'contains' },
        length: { value: '', matchMode: 'contains' },
        height: { value: '', matchMode: 'contains' },
        gsm: { value: '', matchMode: 'contains' },
        used_weight: { value: '', matchMode: 'contains' },
        reason: { value: '', matchMode: 'contains' },
        comment: { value: '', matchMode: 'contains' },
      },
      searchQuery: '',
    },
    stockTransfer: {
      filterToggle: false,
      stockTransferFilters: {
        item_name: { value: '', matchMode: 'contains' },
        id_no: { value: '', matchMode: 'contains' },
        transfer_date: { value: '', matchMode: 'contains' },
        source_warehouse_name: { value: '', matchMode: 'contains' },
        target_warehouse_name: { value: '', matchMode: 'contains' },
        available_weight: { value: '', matchMode: 'contains' },
        transfer_weight: { value: '', matchMode: 'contains' },
      },
      searchQuery: '',
    },
    product: {
      filterToggle: false,
      tableFilters: [],
      searchQuery: '',
      showMobileChecked: false,
    },
    bags: {
      filterToggle: false,
      bagFilters: {
        bag_tag: { value: '', matchMode: 'contains' },
        material_name: { value: '', matchMode: 'contains' },
        form_name: { value: '', matchMode: 'contains' },
        bag_type_name: { value: '', matchMode: 'contains' },
        lamination_types: { value: '', matchMode: 'contains' },
        print_type_name: { value: '', matchMode: 'contains' },
        printing_techonogy_str: { value: '', matchMode: 'contains' },
        width: { value: '', matchMode: 'contains' },
        height: { value: '', matchMode: 'contains' },
        gusset: { value: '', matchMode: 'contains' },
        gsm: { value: '', matchMode: 'contains' },
        roll_width: { value: '', matchMode: 'contains' },
        circum: { value: '', matchMode: 'contains' },
        repeat_length: { value: '', matchMode: 'contains' },
      },
      searchQuery: '',
    },
    finance: {
      filterToggle: false,
      financeFilters: {
        job_date: { value: '', matchMode: 'contains' },
        job_no: { value: '', matchMode: 'contains' },
        design: { value: '', matchMode: 'contains' },
        size_GSM: { value: '', matchMode: 'contains' },
        bag_type: { value: '', matchMode: 'contains' },
        lamination_type: { value: '', matchMode: 'contains' },
        roll_width: { value: '', matchMode: 'contains' },
        repeat_length: { value: '', matchMode: 'contains' },
        total_roll_weight_used: { value: '', matchMode: 'contains' },
        order_qty: { value: '', matchMode: 'contains' },
        qty_made: { value: '', matchMode: 'contains' },
        qty_made_weight: { value: '', matchMode: 'contains' },
        handle_weight_per_bag: { value: '', matchMode: 'contains' },
        wastage_kg: { value: '', matchMode: 'contains' },
        wastage_bags: { value: '', matchMode: 'contains' },
        wastage_percentage: { value: '', matchMode: 'contains' },
        fabric_rate: { value: '', matchMode: 'contains' },
        printing_conversion_rate_converted: {
          value: '',
          matchMode: 'contains',
        },
        bag_conversion_rate_converted: { value: '', matchMode: 'contains' },
        handle_conversion_rate_converted: { value: '', matchMode: 'contains' },
        fabric_amount: { value: '', matchMode: 'contains' },
        printing_conversion_amount: { value: '', matchMode: 'contains' },
        bag_conversion_amount: { value: '', matchMode: 'contains' },
        handle_amount: { value: '', matchMode: 'contains' },

        packing_amount_converted: {
          value: '',
          matchMode: 'contains',
        },
        total: { value: '', matchMode: 'contains' },
        per_pc_selling_price: { value: '', matchMode: 'contains' },
        per_kg_selling_price: { value: '', matchMode: 'contains' },
        selling_price_amount: { value: '', matchMode: 'contains' },
        profite_amount: { value: '', matchMode: 'contains' },
        profit_percentage: { value: '', matchMode: 'contains' },
        customer_name: { value: '', matchMode: 'contains' },
        customer_group: { value: '', matchMode: 'contains' },
        customer_type: { value: '', matchMode: 'contains' },
        present_advisor_name: { value: '', matchMode: 'contains' },
        factory_name: { value: '', matchMode: 'contains' },
        printing_operator_name: { value: '', matchMode: 'contains' },
        bag_making_operator_name: { value: '', matchMode: 'contains' },
      },
      searchQuery: '',
    },
    customer: {
      filterToggle: false,
      customerFilters: {
        party_name: { value: '', matchMode: 'contains' },
        customer_group: { value: '', matchMode: 'contains' },
        order_no: { value: '', matchMode: 'contains' },
        order_date: { value: '', matchMode: 'contains' },
        state: { value: '', matchMode: 'contains' },
        city: { value: '', matchMode: 'contains' },
        mobile_no: { value: '', matchMode: 'contains' },
        present_advisor_name: { value: '', matchMode: 'contains' },
        snoozed_on: { value: '', matchMode: 'contains' },
        snooze_for: { value: '', matchMode: 'contains' },
        comment: { value: '', matchMode: 'contains' },
      },
      field_filter: {
        party_name: [],
        party_type: [],
        present_advisor_name: [],
        customer_group: [],
        city: [],
        state: [],
      },
      balnk_field_filter: {
        party_name: [],
        party_type: [],
        present_advisor_name: [],
        customer_group: [],
        city: [],
        state: [],
      },
      searchQuery: '',
    },
    brokerMargin: {
      filterToggle: false,
      isPendingReward: true,
      pendingBrokerfilterToggle: false,

      brokerMarginFilter: {
        job_date: { value: '', matchMode: 'contains' },
        job_no: { value: '', matchMode: 'contains' },
        job_value: { value: '', matchMode: 'contains' },
        party_name: { value: '', matchMode: 'contains' },
        design: { value: '', matchMode: 'contains' },
        size_GSM: { value: '', matchMode: 'contains' },
        bag_type: { value: '', matchMode: 'contains' },
        lamination_type: { value: '', matchMode: 'contains' },
        supplied_pcs: { value: '', matchMode: 'contains' },
        supplied_kg: { value: '', matchMode: 'contains' },
        weight_per_bag: { value: '', matchMode: 'contains' },
        selling_price_per_pc: { value: '', matchMode: 'contains' },
        selling_price_per_kg: { value: '', matchMode: 'contains' },
        base_rate: { value: '', matchMode: 'contains' },
        actual_block_charge: { value: '', matchMode: 'contains' },
        block_charge_taken_from_customer: { value: '', matchMode: 'contains' },
        freight_charge: { value: '', matchMode: 'contains' },
        freight_charge_paid_by_customer: { value: '', matchMode: 'contains' },
        invoice_date: { value: '', matchMode: 'contains' },
        full_payment_received_date: { value: '', matchMode: 'contains' },
        days: { value: '', matchMode: 'contains' },
        amount_deducted_by_cutomer: { value: '', matchMode: 'contains' },
        calculate_reward: { value: '', matchMode: 'contains' },
        profit: { value: '', matchMode: 'contains' },
        final_reward: { value: '', matchMode: 'contains' },
        reward_transferred: { value: '', matchMode: 'contains' },
        transfer_date: { value: '', matchMode: 'contains' },
        transfer_amount: { value: '', matchMode: 'contains' },
        comment: { value: '', matchMode: 'contains' },
      },
      searchQuery: '',
    },
    trendingProduct: {
      filterToggle: false,
      trendingProductfilter: {
        'product_detail.design_name': { value: '', matchMode: 'contains' },
        'product_detail.size_gsm': { value: '', matchMode: 'contains' },
        'product_detail.gsm': { value: '', matchMode: 'contains' },
        'product_detail.print_type_name': { value: '', matchMode: 'contains' },
        'product_detail.fabric_color_name': {
          value: '',
          matchMode: 'contains',
        },
        'product_detail.customer_group_name': {
          value: '',
          matchMode: 'contains',
        },
        lamination_type: { value: '', matchMode: 'contains' },
        in_stock: { value: '', matchMode: 'contains' },
        pending_order: { value: '', matchMode: 'contains' },
        one_month: { value: '', matchMode: 'contains' },
        three_month: { value: '', matchMode: 'contains' },
        six_month: { value: '', matchMode: 'contains' },
        twelve_month: { value: '', matchMode: 'contains' },
      },
      searchQuery: '',
    },
    productTransfer: {
      filterToggle: false,
      productTransferFilters: {
        product_code: { value: '', matchMode: 'contains' },
        transfer_date: { value: '', matchMode: 'contains' },
        source_warehouse_name: { value: '', matchMode: 'contains' },
        target_warehouse_name: { value: '', matchMode: 'contains' },
        available_qty: { value: '', matchMode: 'contains' },
        transfer_qty: { value: '', matchMode: 'contains' },
      },
      searchQuery: '',
    },
    mobileAppPriceList: {
      filterToggle: false,
      mobileAppFilters: {
        main_image_str: { value: '', matchMode: 'contains' },
        design_name: { value: '', matchMode: 'contains' },
        display_name: { value: '', matchMode: 'contains' },
        bag_type_name: { value: '', matchMode: 'contains' },
        lamination_types: { value: '', matchMode: 'contains' },
        fabric_color_name: { value: '', matchMode: 'contains' },
        sort_order: { value: '', matchMode: 'contains' },
        out_of_stock: { value: '', matchMode: 'contains' },
        gsm: { value: '', matchMode: 'contains' },
        size: { value: '', matchMode: 'contains' },
      },
      searchQuery: '',
    },
    filterToggle: false,
    user: {
      userFilters: {
        emp_no: { value: '', matchMode: 'contains' },
        first_name: { value: '', matchMode: 'contains' },
        email: { value: '', matchMode: 'contains' },
        mobile_no: { value: '', matchMode: 'contains' },
        factory_location_name: { value: '', matchMode: 'contains' },
        dob: { value: '', matchMode: 'contains' },
        joining_date_formatted: { value: '', matchMode: 'contains' },
        type: { value: '', matchMode: 'contains' },
        designation: { value: '', matchMode: 'contains' },
        status: { value: '', matchMode: 'contains' },
      },
      searchQuery: '',
    },
    itemRawMaterials: {
      filterToggle: false,
      itemFilters: {
        name: { value: '', matchMode: 'contains' },
        group_name: { value: '', matchMode: 'contains' },
        primary_unit_name: { value: '', matchMode: 'contains' },
        secondary_unit_name: { value: '', matchMode: 'contains' },
        status: { value: '', matchMode: 'contains' },
      },
      searchQuery: '',
    },
    rollPermissions: {
      filterToggle: false,
      rollFilters: {
        name: { value: '', matchMode: 'contains' },
        status: { value: '', matchMode: 'contains' },
      },
    },
    //  miscmasters
    miscMasters: {
      filteredList: [],
      searchQuery: '',
      masterName: 'Warehouse',
      activeIndex: 0,
    },
    warehouse: {
      filterToggle: false,
      warehouseFilters: {
        code: { value: '', matchMode: 'contains' },
        name: { value: '', matchMode: 'contains' },
        status: { value: '', matchMode: 'contains' },
      },
      searchQuery: '',
      // applied: {},
      // filters: [],
    },
    unit: {
      filterToggle: false,
      unitFilters: {
        code: { value: '', matchMode: 'contains' },
        name: { value: '', matchMode: 'contains' },
        status: { value: '', matchMode: 'contains' },
      },
      searchQuery: '',
      // applied: {},
      // filters: [],
    },
    bank: {
      filterToggle: false,
      bankFilters: {
        code: { value: '', matchMode: 'contains' },
        name: { value: '', matchMode: 'contains' },
        account_name: { value: '', matchMode: 'contains' },
        account_number: { value: '', matchMode: 'contains' },
        IFSC_code: { value: '', matchMode: 'contains' },
        status: { value: '', matchMode: 'contains' },
      },
      searchQuery: '',
      // applied: {},
      // filters: [],
    },
    bagType: {
      filterToggle: false,
      bagTypeFilters: {
        code: { value: '', matchMode: 'contains' },
        name: { value: '', matchMode: 'contains' },
        conversion_rate: { value: '', matchMode: 'contains' },
        handle_weight: { value: '', matchMode: 'contains' },
        status: { value: '', matchMode: 'contains' },
      },
      searchQuery: '',
      // applied: {},
      // filters: [],
    },
    bagCollection: {
      filterToggle: false,
      bagCollectionFilters: {
        order_no: { value: '', matchMode: 'contains' },
        name: { value: '', matchMode: 'contains' },
        status: { value: '', matchMode: 'contains' },
      },
      searchQuery: '',
      // applied: {},
      // filters: [],
    },
    printingType: {
      filterToggle: false,
      printingTypeFilters: {
        code: { value: '', matchMode: 'contains' },
        name: { value: '', matchMode: 'contains' },
        conversion_rate: { value: '', matchMode: 'contains' },
        status: { value: '', matchMode: 'contains' },
      },
      searchQuery: '',
      // applied: {},
      // filters: [],
    },
    printingTechnology: {
      filterToggle: false,
      printingTechnologyFilters: {
        code: { value: '', matchMode: 'contains' },
        name: { value: '', matchMode: 'contains' },
        conversion_rate: { value: '', matchMode: 'contains' },
        status: { value: '', matchMode: 'contains' },
      },
      searchQuery: '',
      // applied: {},
      // filters: [],
    },
    country: {
      filterToggle: false,
      countryFilters: {
        country_code: { value: '', matchMode: 'contains' },
        name: { value: '', matchMode: 'contains' },
        status: { value: '', matchMode: 'contains' },
      },
      searchQuery: '',
      // applied: {},
      // filters: [],
    },
    state: {
      filterToggle: false,
      stateFilters: {
        name: { value: '', matchMode: 'contains' },
        status: { value: '', matchMode: 'contains' },
      },
      searchQuery: '',
      // applied: {},
      // filters: [],
    },
    city: {
      filterToggle: false,
      cityFilters: {
        name: { value: '', matchMode: 'contains' },
        status: { value: '', matchMode: 'contains' },
      },
      searchQuery: '',
      // applied: {},
      // filters: [],
    },
    operatorRole: {
      filterToggle: false,
      operatorRoleFilters: {
        name: { value: '', matchMode: 'contains' },
        comment: { value: '', matchMode: 'contains' },
        status: { value: '', matchMode: 'contains' },
      },
      searchQuery: '',
      // applied: {},
      // filters: [],
    },
    factoryLocation: {
      filterToggle: false,
      factoryLocationFilters: {
        name: { value: '', matchMode: 'contains' },
        status: { value: '', matchMode: 'contains' },
      },
      searchQuery: '',
      // applied: {},
      // filters: [],
    },
    bagMachine: {
      filterToggle: false,
      bagMachineFilters: {
        code: { value: '', matchMode: 'contains' },
        name: { value: '', matchMode: 'contains' },
        status: { value: '', matchMode: 'contains' },
      },
      searchQuery: '',
      // applied: {},
      // filters: [],
    },
    laminationType: {
      filterToggle: false,
      laminationTypeFilters: {
        code: { value: '', matchMode: 'contains' },
        name: { value: '', matchMode: 'contains' },
        layer: { value: '', matchMode: 'contains' },
        status: { value: '', matchMode: 'contains' },
      },
      searchQuery: '',
      // applied: {},
      // filters: [],
    },
    businessType: {
      filterToggle: false,
      businessTypeFilters: {
        name: { value: '', matchMode: 'contains' },
        status: { value: '', matchMode: 'contains' },
      },
      searchQuery: '',
      // applied: {},
      // filters: [],
    },
    itemGroup: {
      filterToggle: false,
      itemGroupFilters: {
        name: { value: '', matchMode: 'contains' },
        status: { value: '', matchMode: 'contains' },
      },
      searchQuery: '',
      // applied: {},
      // filters: [],
    },
    partyType: {
      filterToggle: false,
      partyTypeFilters: {
        name: { value: '', matchMode: 'contains' },
        status: { value: '', matchMode: 'contains' },
      },
      searchQuery: '',
      // applied: {},
      // filters: [],
    },
    market: {
      filterToggle: false,
      marketFilters: {
        name: { value: '', matchMode: 'contains' },
        status: { value: '', matchMode: 'contains' },
      },
      searchQuery: '',
      // applied: {},
      // filters: [],
    },
    industry: {
      filterToggle: false,
      industryFilters: {
        name: { value: '', matchMode: 'contains' },
        status: { value: '', matchMode: 'contains' },
      },
      searchQuery: '',
      // applied: {},
      // filters: [],
    },
    customerSource: {
      filterToggle: false,
      customerSourceFilters: {
        name: { value: '', matchMode: 'contains' },
        status: { value: '', matchMode: 'contains' },
      },
      searchQuery: '',
      // applied: {},
      // filters: [],
    },
    customerSourceDetail: {
      filterToggle: false,
      customerSourceDetailFilters: {
        name: { value: '', matchMode: 'contains' },
        status: { value: '', matchMode: 'contains' },
      },
      searchQuery: '',
      // applied: {},
      // filters: [],
    },
    patchCylinder: {
      filterToggle: false,
      patchCylinderFilters: {
        name: { value: '', matchMode: 'contains' },
        width: { value: '', matchMode: 'contains' },
        cylinder: { value: '', matchMode: 'contains' },
        status: { value: '', matchMode: 'contains' },
      },
      searchQuery: '',
      // applied: {},
      // filters: [],
    },
    customerGroup: {
      filterToggle: false,
      customerGroupFilters: {
        name: { value: '', matchMode: 'contains' },
        status: { value: '', matchMode: 'contains' },
      },
      searchQuery: '',
      // applied: {},
      // filters: [],
    },
    backgroundDesign: {
      filterToggle: false,
      backgroundDesignFilters: {
        name: { value: '', matchMode: 'contains' },
        status: { value: '', matchMode: 'contains' },
      },
      searchQuery: '',
      // applied: {},
      // filters: [],
    },
    handleMaterial: {
      filterToggle: false,
      handleMaterialFilters: {
        name: { value: '', matchMode: 'contains' },
        status: { value: '', matchMode: 'contains' },
      },
      searchQuery: '',
      // applied: {},
      // filters: [],
    },
    bagCapacity: {
      filterToggle: false,
      bagCapacityFilters: {
        size: { value: '', matchMode: 'contains' },
        status: { value: '', matchMode: 'contains' },
      },
      searchQuery: '',
      // applied: {},
      // filters: [],
    },
    form: {
      filterToggle: false,
      formFilters: {
        name: { value: '', matchMode: 'contains' },
        status: { value: '', matchMode: 'contains' },
      },
      searchQuery: '',
      // applied: {},
      // filters: [],
    },
    material: {
      filterToggle: false,
      materialFilters: {
        name: { value: '', matchMode: 'contains' },
        gst: { value: '', matchMode: 'contains' },
        status: { value: '', matchMode: 'contains' },
      },
      searchQuery: '',
      // applied: {},
      // filters: [],
    },
    fabricColor: {
      filterToggle: false,
      fabricColorFilters: {
        name: { value: '', matchMode: 'contains' },
        code: { value: '', matchMode: 'contains' },
        status: { value: '', matchMode: 'contains' },
      },
      searchQuery: '',
      // applied: {},
      // filters: [],
    },
    addressType: {
      filterToggle: false,
      addressTypeFilters: {
        name: { value: '', matchMode: 'contains' },
        status: { value: '', matchMode: 'contains' },
      },
      // applied: {},
      // filters: [],
    },
    machineType: {
      filterToggle: false,
      machineTypeFilters: {
        gsm: { value: '', matchMode: 'contains' },
        length: { value: '', matchMode: 'contains' },
        status: { value: '', matchMode: 'contains' },
      },
      searchQuery: '',
      // applied: {},
      // filters: [],
    },
    gsmLength: {
      filterToggle: false,
      gsmLengthFilters: {
        code: { value: '', matchMode: 'contains' },
        name: { value: '', matchMode: 'contains' },
        bag_type_str: { value: '', matchMode: 'contains' },
        printing_technology_str: { value: '', matchMode: 'contains' },
        status: { value: '', matchMode: 'contains' },
      },
      searchQuery: '',
      // applied: {},
      // filters: [],
    },
    machines: {
      filterToggle: false,
      machinesFilters: {
        name: { value: '', matchMode: 'contains' },
        machine_type_name: { value: '', matchMode: 'contains' },
        purchase_date: { value: '', matchMode: 'contains' },
        status: { value: '', matchMode: 'contains' },
      },
      searchQuery: '',
      // applied: {},
      // filters: [],
    },
    velcro: {
      filterToggle: false,
      velcroFilters: {
        size: { value: '', matchMode: 'contains' },
        status: { value: '', matchMode: 'contains' },
      },
      searchQuery: '',
      // applied: {},
      // filters: [],
    },
    customerRating: {
      filterToggle: false,
      customerRatingFilters: {
        size: { value: '', matchMode: 'contains' },
        status: { value: '', matchMode: 'contains' },
      },
      searchQuery: '',
    },
    // End Miscmasters
    // sales
    order: {
      filterToggle: false,
      jobFilterToggle: false,
      orderFilters: {
        order_no: { value: '', matchMode: 'contains' },
        party_name_detail: { value: '', matchMode: 'contains' },
        order_date: { value: '', matchMode: 'contains' },
        days: { value: '', matchMode: 'contains' },
        order_qty: { value: '', matchMode: 'contains' },
        total_amount: { value: '', matchMode: 'contains' },
        advance_amount: { value: '', matchMode: 'contains' },
        present_advisor_name: { value: '', matchMode: 'contains' },
        transporter_name: { value: '', matchMode: 'contains' },
        comment: { value: '', matchMode: 'contains' },
        is_cc_attach_str: { value: '', matchMode: 'contains' },
        dispatch_after_payment_str: { value: '', matchMode: 'contains' },
        multiple_billing_str: { value: '', matchMode: 'contains' },
        dispatch_on_invoice_str: { value: '', matchMode: 'contains' },
        status_str: { value: '', matchMode: 'contains' },
      },
      jobFilters: {
        job_no: { value: '', matchMode: 'contains' },
        due_date: { value: '', matchMode: 'contains' },
        'product_detail.design': { value: '', matchMode: 'contains' },
        'product_detail.code': { value: '', matchMode: 'contains' },
        'bag_detail.bag_type': { value: '', matchMode: 'contains' },
        'product_detail.fabric_color': { value: '', matchMode: 'contains' },
        qty: { value: '', matchMode: 'contains' },
        weight: { value: '', matchMode: 'contains' },
        per_pc: { value: '', matchMode: 'contains' },
        per_kg: { value: '', matchMode: 'contains' },
        pc_rate: { value: '', matchMode: 'contains' },
        kg_rate: { value: '', matchMode: 'contains' },
        stereo_charge: { value: '', matchMode: 'contains' },
        additional_charge: { value: '', matchMode: 'contains' },
        final_amount: { value: '', matchMode: 'contains' },
        bag_sent: { value: '', matchMode: 'contains' },
        lrSent: { value: '', matchMode: 'contains' },
        status_str: { value: '', matchMode: 'contains' },
      },
      searchQuery: '',
      isJobsOnly: false,
    },
    proforma: {
      filterToggle: false,
      proformaFilters: {
        invoice_no: { value: '', matchMode: 'contains' },
        invoice_date: { value: '', matchMode: 'contains' },
        party_name_detail: { value: '', matchMode: 'contains' },
        transporter_name: { value: '', matchMode: 'contains' },
        present_advisor_name: { value: '', matchMode: 'contains' },
        pc_qty: { value: '', matchMode: 'contains' },
        kg_qty: { value: '', matchMode: 'contains' },
        final_amount: { value: '', matchMode: 'contains' },
        comment: { value: '', matchMode: 'contains' },
      },
      searchQuery: '',
    },
    sales: {
      filterToggle: false,
      salesFilters: {
        invoice_no: { value: '', matchMode: 'contains' },
        invoice_date: { value: '', matchMode: 'contains' },
        party_name_detail: { value: '', matchMode: 'contains' },
        transporter_name: { value: '', matchMode: 'contains' },
        tripta_no: { value: '', matchMode: 'contains' },
        present_advisor_name: { value: '', matchMode: 'contains' },
        pc_qty: { value: '', matchMode: 'contains' },
        kg_qty: { value: '', matchMode: 'contains' },
        final_amount: { value: '', matchMode: 'contains' },
        comment: { value: '', matchMode: 'contains' },
        order_no: { value: '', matchMode: 'contains' },
      },
      searchQuery: '',
    },
    trendingProductAndBag: {
      activeIndex: 0,
    },
    consumptionDashboard: {
      activeIndex: 0,
    },
    ppPriceHistory: {
      filterToggle: false,
      ppPriceHistoryFilters: {
        date: { value: '', matchMode: 'contains' },
        price: { value: '', matchMode: 'contains' },
      },
    },
    mfgLive: {
      filterToggle: false,
      suggestedFilterToggle: false,
      assignedFilterToggle: false,
      mfgLivePrintingFilter: {
        sr_no: { value: '', matchMode: 'contains' },
        id_no: { value: '', matchMode: 'contains' },
        color: { value: '', matchMode: 'contains' },
        gsm: { value: '', matchMode: 'contains' },
        size: { value: '', matchMode: 'contains' },
        net_weight: { value: '', matchMode: 'contains' },
        item_name: { value: '', matchMode: 'contains' },
        print_technology_name: { value: '', matchMode: 'contains' },
        lamination: { value: '', matchMode: 'contains' },
        design_name: { value: '', matchMode: 'contains' },
        is_slit: { value: '', matchMode: 'contains' },
        parent_id: { value: '', matchMode: 'contains' },
      },
      mfgFilters: {
        srno: { value: '', matchMode: 'contains' },
        roll_available_str: { value: '', matchMode: 'contains' },
        hndl_str: { value: '', matchMode: 'contains' },
        old_str_str: { value: '', matchMode: 'contains' },
        str_ord_str: { value: '', matchMode: 'contains' },
        str_rcv_str: { value: '', matchMode: 'contains' },
        print_str: { value: '', matchMode: 'contains' },
        bag_made_str: { value: '', matchMode: 'contains' },
        bag_sent_str: { value: '', matchMode: 'contains' },
        lr_sent_str: { value: '', matchMode: 'contains' },
        job_date: { value: '', matchMode: 'contains' },
        days: { value: '', matchMode: 'contains' },
        design: { value: '', matchMode: 'contains' },
        amount: { value: '', matchMode: 'contains' },
        background_design_name: { value: '', matchMode: 'contains' },
        size_str: { value: '', matchMode: 'contains' },
        gsm: { value: '', matchMode: 'contains' },
        bag_type: { value: '', matchMode: 'contains' },
        fabric_color: { value: '', matchMode: 'contains' },
        roll_width: { value: '', matchMode: 'contains' },
        handle_material_name: { value: '', matchMode: 'contains' },
        handle_color: { value: '', matchMode: 'contains' },
        cylinder: { value: '', matchMode: 'contains' },
        qty: { value: '', matchMode: 'contains' },
        per_pc: { value: '', matchMode: 'contains' },
        per_kg: { value: '', matchMode: 'contains' },
        kg_qty: { value: '', matchMode: 'contains' },
        rate: { value: '', matchMode: 'contains' },
        kg_rate: { value: '', matchMode: 'contains' },
        stereo_charge: { value: '', matchMode: 'contains' },
        act_stereo_charge: { value: '', matchMode: 'contains' },
        additional_charge: { value: '', matchMode: 'contains' },
        comment: { value: '', matchMode: 'contains' },
        party_name: { value: '', matchMode: 'contains' },
        present_advisor_name: { value: '', matchMode: 'contains' },
        is_laminated_str: { value: '', matchMode: 'contains' },
        wastage: { value: '', matchMode: 'contains' },
        profit: { value: '', matchMode: 'contains' },
        act_kg_rate: { value: '', matchMode: 'contains' },
        theoretical_kg_rate: { value: '', matchMode: 'contains' },
        job_no: { value: '', matchMode: 'contains' },
        created_by_name: { value: '', matchMode: 'contains' },
        factory: { value: '', matchMode: 'contains' },
      },
      field_filter: {
        bag_type: [],
        design: [],
        present_advisor_name: [],
        party_name: [],
        print_type_name: [],
        lamination_type: [],
        fabric_color: [],
        gsm: [],
        screen_print: 'ALL',
      },
      balnk_field_filter: {
        bag_type: [],
        design: [],
        present_advisor_name: [],
        party_name: [],
        print_type_name: [],
        lamination_type: [],
        fabric_color: [],
        gsm: [],
        screen_print: 'ALL',
      },
      print_field_filter: {
        color: [],
        design_name: [],
        gsm: [],
        lamination: [],
        material: [],
        warehouse_name: [],
        width: [],
      },
      blank_print_field_filter: {
        color: [],
        design_name: [],
        gsm: [],
        lamination: [],
        material: [],
        warehouse_name: [],
        width: [],
      },
      searchQuery: '',
    },
    mfgLiveFlexo: {
      filterToggle: false,
      mfgFlexoPrint: {
        tabelFilterToggle: false,
        assignedFilterToggle: false,
        suggestedFilterToggle: false,
        mfgFlexoPrintPrintingFilter: {
          sr_no: { value: '', matchMode: 'contains' },
          id_no: { value: '', matchMode: 'contains' },
          color: { value: '', matchMode: 'contains' },
          gsm: { value: '', matchMode: 'contains' },
          size: { value: '', matchMode: 'contains' },
          net_weight: { value: '', matchMode: 'contains' },
          item_name: { value: '', matchMode: 'contains' },
          print_technology_name: { value: '', matchMode: 'contains' },
          lamination: { value: '', matchMode: 'contains' },
          design_name: { value: '', matchMode: 'contains' },
          is_slit: { value: '', matchMode: 'contains' },
          parent_id: { value: '', matchMode: 'contains' },
        },

        mfgFlexoPrintFilter: {
          job_date: { value: '', matchMode: 'contains' },
          days: { value: '', matchMode: 'contains' },
          design: { value: '', matchMode: 'contains' },
          size: { value: '', matchMode: 'contains' },
          gsm: { value: '', matchMode: 'contains' },
          bag_type: { value: '', matchMode: 'contains' },
          fabric_color: { value: '', matchMode: 'contains' },
          handle_color: { value: '', matchMode: 'contains' },
          qty: { value: '', matchMode: 'contains' },
          roll_available: { value: '', matchMode: 'contains' },
          block: { value: '', matchMode: 'contains' },
          str_rcv_str: { value: '', matchMode: 'contains' },
          notification_to_print: { value: '', matchMode: 'contains' },
          text_to_print: { value: '', matchMode: 'contains' },
          total_roll: { value: '', matchMode: 'contains' },
          machineCounter: { value: '', matchMode: 'contains' },
          comment: { value: '', matchMode: 'contains' },
        },
      },
      mfgRtoRPrinting: {
        tabelFilterToggle: false,
        suggestedFilterToggle: false,
        assignedFilterToggle: false,
        mfgRtoRPrintPrintingFilter: {
          // unique_id: { value: '', matchMode: 'contains' },
          id_no: { value: '', matchMode: 'contains' },
          color: { value: '', matchMode: 'contains' },
          gsm: { value: '', matchMode: 'contains' },
          size: { value: '', matchMode: 'contains' },
          net_weight: { value: '', matchMode: 'contains' },
          item_name: { value: '', matchMode: 'contains' },
          print_technology_name: { value: '', matchMode: 'contains' },
          lamination: { value: '', matchMode: 'contains' },
          design_name: { value: '', matchMode: 'contains' },
          is_slit: { value: '', matchMode: 'contains' },
          parent_id: { value: '', matchMode: 'contains' },
        },

        mfgRtoRPrintingFilter: {
          job_date: { value: '', matchMode: 'contains' },
          days: { value: '', matchMode: 'contains' },
          design: { value: '', matchMode: 'contains' },
          size: { value: '', matchMode: 'contains' },
          gsm: { value: '', matchMode: 'contains' },
          bag_type: { value: '', matchMode: 'contains' },
          fabric_color: { value: '', matchMode: 'contains' },
          handle_color: { value: '', matchMode: 'contains' },
          qty: { value: '', matchMode: 'contains' },
          roll_available: { value: '', matchMode: 'contains' },
          block: { value: '', matchMode: 'contains' },
          str_rcv_str: { value: '', matchMode: 'contains' },
          notification_to_print: { value: '', matchMode: 'contains' },
          text_to_print: { value: '', matchMode: 'contains' },
          total_roll: { value: '', matchMode: 'contains' },
          machineCounter: { value: '', matchMode: 'contains' },
          comment: { value: '', matchMode: 'contains' },
        },
      },
      mfgLiveRotoGravure: {
        tabelFilterToggle: false,
        suggestedFilterToggle: false,
        assignedFilterToggle: false,
        mfgLiveRotoGravurePrintingFilter: {
          // unique_id: { value: '', matchMode: 'contains' },
          id_no: { value: '', matchMode: 'contains' },
          color: { value: '', matchMode: 'contains' },
          gsm: { value: '', matchMode: 'contains' },
          size: { value: '', matchMode: 'contains' },
          net_weight: { value: '', matchMode: 'contains' },
          item_name: { value: '', matchMode: 'contains' },
          print_technology_name: { value: '', matchMode: 'contains' },
          lamination: { value: '', matchMode: 'contains' },
          design_name: { value: '', matchMode: 'contains' },
          is_slit: { value: '', matchMode: 'contains' },
          parent_id: { value: '', matchMode: 'contains' },
        },

        mfgLiveRotoGravureFilter: {
          job_date: { value: '', matchMode: 'contains' },
          days: { value: '', matchMode: 'contains' },
          design: { value: '', matchMode: 'contains' },
          size: { value: '', matchMode: 'contains' },
          gsm: { value: '', matchMode: 'contains' },
          bag_type: { value: '', matchMode: 'contains' },
          fabric_color: { value: '', matchMode: 'contains' },
          handle_color: { value: '', matchMode: 'contains' },
          qty: { value: '', matchMode: 'contains' },
          roll_available: { value: '', matchMode: 'contains' },
          block: { value: '', matchMode: 'contains' },
          str_rcv_str: { value: '', matchMode: 'contains' },
          notification_to_print: { value: '', matchMode: 'contains' },
          text_to_print: { value: '', matchMode: 'contains' },
          total_roll: { value: '', matchMode: 'contains' },
          machineCounter: { value: '', matchMode: 'contains' },
          comment: { value: '', matchMode: 'contains' },
        },
      },
      mfgLiveBtoBPrinting: {
        tabelFilterToggle: false,
        mfgLiveBtoBFilter: {
          sr_no: { value: '', matchMode: 'contains' },
          due_date: { value: '', matchMode: 'contains' },
          days: { value: '', matchMode: 'contains' },
          design: { value: '', matchMode: 'contains' },
          background_design_name: { value: '', matchMode: 'contains' },
          size: { value: '', matchMode: 'contains' },
          gsm: { value: '', matchMode: 'contains' },
          bag_type: { value: '', matchMode: 'contains' },
          stereo: { value: '', matchMode: 'contains' },
          addBags: { value: '', matchMode: 'contains' },
          created_by_name: { value: '', matchMode: 'contains' },
          comment: { value: '', matchMode: 'contains' },
        },
      },
      mfgLiveHotStamping: {
        tabelFilterToggle: false,
        mfgLiveHotStampingFilter: {
          sr_no: { value: '', matchMode: 'contains' },
          due_date: { value: '', matchMode: 'contains' },
          days: { value: '', matchMode: 'contains' },
          design: { value: '', matchMode: 'contains' },
          background_design_name: { value: '', matchMode: 'contains' },
          size: { value: '', matchMode: 'contains' },
          gsm: { value: '', matchMode: 'contains' },
          bag_type: { value: '', matchMode: 'contains' },
          stereo: { value: '', matchMode: 'contains' },
          addBags: { value: '', matchMode: 'contains' },
          created_by_name: { value: '', matchMode: 'contains' },
          comment: { value: '', matchMode: 'contains' },
        },
      },
      mfgLivePlainPrint: {
        tabelFilterToggle: false,
        suggestedFilterToggle: false,
        assignedFilterToggle: false,
        mfgLivePlainPrintingFilter: {
          // unique_id: { value: '', matchMode: 'contains' },
          id_no: { value: '', matchMode: 'contains' },
          color: { value: '', matchMode: 'contains' },
          gsm: { value: '', matchMode: 'contains' },
          size: { value: '', matchMode: 'contains' },
          net_weight: { value: '', matchMode: 'contains' },
          item_name: { value: '', matchMode: 'contains' },
          print_technology_name: { value: '', matchMode: 'contains' },
          lamination: { value: '', matchMode: 'contains' },
          design_name: { value: '', matchMode: 'contains' },
          is_slit: { value: '', matchMode: 'contains' },
          parent_id: { value: '', matchMode: 'contains' },
        },

        mfgLivePlainPrintFilter: {
          job_date: { value: '', matchMode: 'contains' },
          days: { value: '', matchMode: 'contains' },
          design: { value: '', matchMode: 'contains' },
          size: { value: '', matchMode: 'contains' },
          gsm: { value: '', matchMode: 'contains' },
          bag_type: { value: '', matchMode: 'contains' },
          fabric_color: { value: '', matchMode: 'contains' },
          handle_color: { value: '', matchMode: 'contains' },
          qty: { value: '', matchMode: 'contains' },
          roll_available: { value: '', matchMode: 'contains' },
          block: { value: '', matchMode: 'contains' },
          str_rcv_str: { value: '', matchMode: 'contains' },
          notification_to_print: { value: '', matchMode: 'contains' },
          text_to_print: { value: '', matchMode: 'contains' },
          total_roll: { value: '', matchMode: 'contains' },
          machineCounter: { value: '', matchMode: 'contains' },
          comment: { value: '', matchMode: 'contains' },
        },
      },
      mfgLiveSemiROTOPrint: {
        tabelFilterToggle: false,
        suggestedFilterToggle: false,
        assignedFilterToggle: false,
        mfgLiveSemiROTOPrintingFilter: {
          // unique_id: { value: '', matchMode: 'contains' },
          id_no: { value: '', matchMode: 'contains' },
          color: { value: '', matchMode: 'contains' },
          gsm: { value: '', matchMode: 'contains' },
          size: { value: '', matchMode: 'contains' },
          net_weight: { value: '', matchMode: 'contains' },
          item_name: { value: '', matchMode: 'contains' },
          print_technology_name: { value: '', matchMode: 'contains' },
          lamination: { value: '', matchMode: 'contains' },
          design_name: { value: '', matchMode: 'contains' },
          is_slit: { value: '', matchMode: 'contains' },
          parent_id: { value: '', matchMode: 'contains' },
        },
        mfgLiveSemiROTOPrintFilter: {
          job_date: { value: '', matchMode: 'contains' },
          days: { value: '', matchMode: 'contains' },
          design: { value: '', matchMode: 'contains' },
          size: { value: '', matchMode: 'contains' },
          gsm: { value: '', matchMode: 'contains' },
          bag_type: { value: '', matchMode: 'contains' },
          fabric_color: { value: '', matchMode: 'contains' },
          handle_color: { value: '', matchMode: 'contains' },
          qty: { value: '', matchMode: 'contains' },
          roll_available: { value: '', matchMode: 'contains' },
          block: { value: '', matchMode: 'contains' },
          str_rcv_str: { value: '', matchMode: 'contains' },
          notification_to_print: { value: '', matchMode: 'contains' },
          text_to_print: { value: '', matchMode: 'contains' },
          total_roll: { value: '', matchMode: 'contains' },
          machineCounter: { value: '', matchMode: 'contains' },
          comment: { value: '', matchMode: 'contains' },
        },
      },
      mfgLiveZipperPrint: {
        tabelFilterToggle: false,
        finalPrintedRollsToggle: false,
        consumedRollsToggle: false,
        mfgLiveZipperRollConsumptionFilter: {
          id_no: { value: '', matchMode: 'contains' },
          color: { value: '', matchMode: 'contains' },
          gsm: { value: '', matchMode: 'contains' },
          size: { value: '', matchMode: 'contains' },
          net_weight: { value: '', matchMode: 'contains' },
          item_name: { value: '', matchMode: 'contains' },
          lamination: { value: '', matchMode: 'contains' },
          design_name: { value: '', matchMode: 'contains' },
          is_slit: { value: '', matchMode: 'contains' },
          parent_id: { value: '', matchMode: 'contains' },
        },

        mfgLiveZipperPrintFilter: {
          job_date: { value: '', matchMode: 'contains' },
          days: { value: '', matchMode: 'contains' },
          design: { value: '', matchMode: 'contains' },
          size: { value: '', matchMode: 'contains' },
          gsm: { value: '', matchMode: 'contains' },
          bag_type: { value: '', matchMode: 'contains' },
          handle_material_name: { value: '', matchMode: 'contains' },
          handle_color: { value: '', matchMode: 'contains' },
          handle_length: { value: '', matchMode: 'contains' },
          is_laminated: { value: '', matchMode: 'contains' },
          qty: { value: '', matchMode: 'contains' },
          total_roll: { value: '', matchMode: 'contains' },
          machine_count: { value: '', matchMode: 'contains' },
          comment: { value: '', matchMode: 'contains' },
        },
      },
      mfgLiveLoopHandlePrint: {
        tabelFilterToggle: false,
        finalPrintedRollsToggle: false,
        consumedRollsToggle: false,
        mfgLiveLoopHandleConsumptionFilter: {
          id_no: { value: '', matchMode: 'contains' },
          color: { value: '', matchMode: 'contains' },
          gsm: { value: '', matchMode: 'contains' },
          size: { value: '', matchMode: 'contains' },
          net_weight: { value: '', matchMode: 'contains' },
          item_name: { value: '', matchMode: 'contains' },
          lamination: { value: '', matchMode: 'contains' },
          design_name: { value: '', matchMode: 'contains' },
          is_slit: { value: '', matchMode: 'contains' },
          parent_id: { value: '', matchMode: 'contains' },
        },

        mfgLiveLoopHandleFilter: {
          job_date: { value: '', matchMode: 'contains' },
          days: { value: '', matchMode: 'contains' },
          design: { value: '', matchMode: 'contains' },
          size: { value: '', matchMode: 'contains' },
          gsm: { value: '', matchMode: 'contains' },
          bag_type: { value: '', matchMode: 'contains' },
          handle_material_name: { value: '', matchMode: 'contains' },
          handle_color: { value: '', matchMode: 'contains' },
          handle_length: { value: '', matchMode: 'contains' },
          is_laminated: { value: '', matchMode: 'contains' },
          qty: { value: '', matchMode: 'contains' },
          total_roll: { value: '', matchMode: 'contains' },
          machine_count: { value: '', matchMode: 'contains' },
          comment: { value: '', matchMode: 'contains' },
        },
      },
      mfgLiveBoxMaking: {
        tabelFilterToggle: false,
        finalPrintedRollsToggle: false,
        consumedRollsToggle: false,
        mfgLiveBoxMakingConsumptionFilter: {
          id_no: { value: '', matchMode: 'contains' },
          color: { value: '', matchMode: 'contains' },
          gsm: { value: '', matchMode: 'contains' },
          size: { value: '', matchMode: 'contains' },
          net_weight: { value: '', matchMode: 'contains' },
          item_name: { value: '', matchMode: 'contains' },
          lamination: { value: '', matchMode: 'contains' },
          design_name: { value: '', matchMode: 'contains' },
          is_slit: { value: '', matchMode: 'contains' },
          parent_id: { value: '', matchMode: 'contains' },
        },
        mfgLiveBoxMakingFilter: {
          job_date: { value: '', matchMode: 'contains' },
          days: { value: '', matchMode: 'contains' },
          design: { value: '', matchMode: 'contains' },
          size: { value: '', matchMode: 'contains' },
          gsm: { value: '', matchMode: 'contains' },
          bag_type: { value: '', matchMode: 'contains' },
          handle_material_name: { value: '', matchMode: 'contains' },
          handle_color: { value: '', matchMode: 'contains' },
          handle_length: { value: '', matchMode: 'contains' },
          is_laminated: { value: '', matchMode: 'contains' },
          qty: { value: '', matchMode: 'contains' },
          total_roll: { value: '', matchMode: 'contains' },
          machine_count: { value: '', matchMode: 'contains' },
          comment: { value: '', matchMode: 'contains' },
        },
      },
      print_field_filter: {
        color: [],
        design_name: [],
        gsm: [],
        lamination: [],
        material: [],
        warehouse_name: [],
        width: [],
      },
      blank_print_field_filter: {
        color: [],
        design_name: [],
        gsm: [],
        lamination: [],
        material: [],
        warehouse_name: [],
        width: [],
      },
    },
    purchaseOrder: {
      filterToggle: false,
      searchQuery: '',
      purchaseOrderFilters: {
        po_number: { value: '', matchMode: 'contains' },
        supplier_name: { value: '', matchMode: 'contains' },
        warehouse_name: { value: '', matchMode: 'contains' },
        item_name: { value: '', matchMode: 'contains' },
        design_name: { value: '', matchMode: 'contains' },
        purchase_date: { value: '', matchMode: 'contains' },
        due_date: { value: '', matchMode: 'contains' },
        days: { value: '', matchMode: 'contains' },
        total: { value: '', matchMode: 'contains' },
        status: { value: '', matchMode: 'contains' },
        created_by_name: { value: '', matchMode: 'contains' },
      },
    },
    purchaseReceive: {
      filterToggle: false,
      searchQuery: '',
      purchaseReceiveFilters: {
        po_number: { value: '', matchMode: 'contains' },
        receive_date: { value: '', matchMode: 'contains' },
        item_name: { value: '', matchMode: 'contains' },
        id_no: { value: '', matchMode: 'contains' },
        color: { value: '', matchMode: 'contains' },
        design_name: { value: '', matchMode: 'contains' },
        warehouse_name: { value: '', matchMode: 'contains' },
        width: { value: '', matchMode: 'contains' },
        length: { value: '', matchMode: 'contains' },
        net_weight: { value: '', matchMode: 'contains' },
        rate: { value: '', matchMode: 'contains' },
      },
    },
    nlRequirement: {
      isStockWithoutOrder: false,
      stockWithoutOrderFilterToggle: false,
      filterToggle: false,
      searchQuery: '',
    },
    prePrintedStatus: {
      filterToggle: false,
      completedFilterToggle: false,
      prePrintedFilter: {
        order_date: { value: '', matchMode: 'contains' },
        purchase_date: { value: '', matchMode: 'contains' },
        design_date: { value: '', matchMode: 'contains' },
        proof_rcv_date: { value: '', matchMode: 'contains' },
        proof_ok_date: { value: '', matchMode: 'contains' },
        received_date: { value: '', matchMode: 'contains' },
        design_name: { value: '', matchMode: 'contains' },
        base_fabric: { value: '', matchMode: 'contains' },
        lamination: { value: '', matchMode: 'contains' },
        gsm: { value: '', matchMode: 'contains' },
        // old_str: { value: '', matchMode: 'contains' },
        old_str_str: { value: '', matchMode: 'contains' },
        po_qty: { value: '', matchMode: 'contains' },
        kg_qty: { value: '', matchMode: 'contains' },
        qty: { value: '', matchMode: 'contains' },
        rate: { value: '', matchMode: 'contains' },
        amount: { value: '', matchMode: 'contains' },
        bag_size: { value: '', matchMode: 'contains' },
        ne_white_cylinder: { value: '', matchMode: 'contains' },
        new_white_cylinder_qty: { value: '', matchMode: 'contains' },
        new_white_cylinder_rate: { value: '', matchMode: 'contains' },
        reengraved_cylinder_qty: { value: '', matchMode: 'contains' },
        reengraved_cylinder_rate: { value: '', matchMode: 'contains' },
        recieved_net_weight: { value: '', matchMode: 'contains' },
        cylinder_charge_from_customer: { value: '', matchMode: 'contains' },
        cylinder_charge_from_po: { value: '', matchMode: 'contains' },
        job_no: { value: '', matchMode: 'contains' },
        po_number: { value: '', matchMode: 'contains' },
        supplier_name: { value: '', matchMode: 'contains' },
      },
      completedPrePrintedFilters: {
        order_date: { value: '', matchMode: 'contains' },
        purchase_date: { value: '', matchMode: 'contains' },
        design_date: { value: '', matchMode: 'contains' },
        proof_rcv_date: { value: '', matchMode: 'contains' },
        proof_ok_date: { value: '', matchMode: 'contains' },
        received_date: { value: '', matchMode: 'contains' },
        design_name: { value: '', matchMode: 'contains' },
        base_fabric: { value: '', matchMode: 'contains' },
        lamination: { value: '', matchMode: 'contains' },
        gsm: { value: '', matchMode: 'contains' },
        // old_str: { value: '', matchMode: 'contains' },
        old_str_str: { value: '', matchMode: 'contains' },
        po_qty: { value: '', matchMode: 'contains' },
        kg_qty: { value: '', matchMode: 'contains' },
        qty: { value: '', matchMode: 'contains' },
        rate: { value: '', matchMode: 'contains' },
        amount: { value: '', matchMode: 'contains' },
        bag_size: { value: '', matchMode: 'contains' },
        ne_white_cylinder: { value: '', matchMode: 'contains' },
        new_white_cylinder_qty: { value: '', matchMode: 'contains' },
        new_white_cylinder_rate: { value: '', matchMode: 'contains' },
        reengraved_cylinder_qty: { value: '', matchMode: 'contains' },
        reengraved_cylinder_rate: { value: '', matchMode: 'contains' },
        recieved_net_weight: { value: '', matchMode: 'contains' },
        cylinder_charge_from_customer: { value: '', matchMode: 'contains' },
        cylinder_charge_from_po: { value: '', matchMode: 'contains' },
        job_no: { value: '', matchMode: 'contains' },
        po_number: { value: '', matchMode: 'contains' },
        supplier_name: { value: '', matchMode: 'contains' },
      },
      searchQuery: '',
      isCompleted: false,
    },
    orderOperator: {
      searchQuery: '',
      orderOperatorFilter: {
        filteredList: [],
        filterCheckedData: [],
        width: [],
        height: [],
        gusset: [],
        gsm: [],
      },
    },
    salesDashboard: {
      customerFollowUp: {
        filterToggle: false,
      },
    },
    adminDashboard: {
      warehouse: '',
    },
  },
  targetMasterInitialState: {
    isUpdate: false,
    advisor_id: '',
    year: '',
    target_data: [
      {
        month: 1,
        new_target: '',
        repeat_target: '',
      },
      {
        month: 2,
        new_target: '',
        repeat_target: '',
      },
      {
        month: 3,
        new_target: '',
        repeat_target: '',
      },
      {
        month: 4,
        new_target: '',
        repeat_target: '',
      },
      {
        month: 5,
        new_target: '',
        repeat_target: '',
      },
      {
        month: 6,
        new_target: '',
        repeat_target: '',
      },
      {
        month: 7,
        new_target: '',
        repeat_target: '',
      },
      {
        month: 8,
        new_target: '',
        repeat_target: '',
      },
      {
        month: 9,
        new_target: '',
        repeat_target: '',
      },
      {
        month: 10,
        new_target: '',
        repeat_target: '',
      },
      {
        month: 11,
        new_target: '',
        repeat_target: '',
      },
      {
        month: 12,
        new_target: '',
        repeat_target: '',
      },
    ],
  },
  advisorTargetMasterData: {
    isUpdate: false,
  },
};

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setFilterLoading: (state, action) => {
      state.filterLoading = action.payload;
    },
    setAllFilters: (state, action) => {
      state.allFilters = action.payload;
    },
    resetAllFilters: state => {
      state.allFilters = initialState.allFilters;
    },
    setAllCommon: (state, action) => {
      state.allCommon = action.payload;
    },
    resetAllCommon: (state, action) => {
      state.allCommon = action.payload;
    },
    setAdvisorTargetMasterData: (state, action) => {
      state.advisorTargetMasterData = action.payload;
    },
  },
});

export const {
  setFilterLoading,
  setAllFilters,
  setAllCommon,
  resetAllCommon,
  setAdvisorTargetMasterData,
} = commonSlice.actions;

export default commonSlice.reducer;
