import { createSlice } from '@reduxjs/toolkit';

export let permission = {
  name: '',
  comment: '',
  is_active: 1,
  permission: [
    {
      isSelectedAll: 0,
      main_module_id: '649444153094b3abaf670da1',
      name: 'Dashboard',
      sub_module_permission: [
        {
          _id: '649444d717a12f18522ad575',
          name: 'Admin Dashboard',
          create: 0,
          edit: 0,
          view: 0,
          export: 0,
          import: 0,
          delete: 0,
          print: 0,
        },
        {
          _id: '6494512d774cc1ac05c03ebc',
          name: 'Sales Dashboard',
          create: 0,
          edit: 0,
          view: 0,
          export: 0,
          import: 0,
          delete: 0,
          print: 0,
        },
        {
          _id: '64945145774cc1ac05c03ec0',
          name: 'Old Customer Follow-Ups',
          create: 0,
          edit: 0,
          view: 0,
          export: 0,
          import: 0,
          delete: 0,
          print: 0,
        },
      ],
    },
    {
      isSelectedAll: 0,
      main_module_id: '649450a4774cc1ac05c03e98',
      name: 'Parties',
      is_active: true,
      sub_module_permission: [
        {
          _id: '64945187774cc1ac05c03ec6',
          name: 'All',
          create: 0,
          edit: 0,
          view: 0,
          export: 0,
          import: 0,
          delete: 0,
          print: 0,
        },
      ],
    },
    {
      isSelectedAll: 0,
      main_module_id: '649450af774cc1ac05c03e9c',
      name: 'Inventory',
      is_active: true,
      sub_module_permission: [
        {
          sub_module_id: '649451a2774cc1ac05c03eca',
          name: 'Consumption Dashboard',
          create: 0,
          edit: 0,
          view: 0,
          export: 0,
          import: 0,
          delete: 0,
          print: 0,
        },
        {
          sub_module_id: '649451b7774cc1ac05c03ece',
          name: 'Stock(Raw Material)',
          create: 0,
          edit: 0,
          view: 0,
          export: 0,
          import: 0,
          delete: 0,
          print: 0,
        },
        {
          sub_module_id: '649451c5774cc1ac05c03ed6',
          name: 'Stock Transfer(Raw Material)',
          create: 0,
          edit: 0,
          view: 0,
          export: 0,
          import: 0,
          delete: 0,
          print: 0,
        },
        {
          sub_module_id: '649451bf774cc1ac05c03ed2',
          name: 'Stock Consumption(Raw Material)',
          create: 0,
          edit: 0,
          view: 0,
          export: 0,
          import: 0,
          delete: 0,
          print: 0,
        },
      ],
    },
    {
      isSelectedAll: 0,
      main_module_id: '649450d2774cc1ac05c03eb0',
      name: 'Product',
      sub_module_permission: [
        {
          sub_module_id: '649451fa774cc1ac05c03eda',
          name: 'Trending Products & Bag Tag Consumption',
          create: 0,
          edit: 0,
          view: 0,
          export: 0,
          import: 0,
          delete: 0,
          print: 0,
        },
        {
          sub_module_id: '64945208774cc1ac05c03ee2',
          name: 'Products',
          create: 0,
          edit: 0,
          view: 0,
          export: 0,
          import: 0,
          delete: 0,
          print: 0,
        },
        {
          sub_module_id: '64945213774cc1ac05c03ee6',
          name: 'Product Transfer',
          create: 0,
          edit: 0,
          view: 0,
          export: 0,
          import: 0,
          delete: 0,
          print: 0,
        },
        {
          sub_module_id: '64945202774cc1ac05c03ede',
          name: 'Bags',
          create: 0,
          edit: 0,
          view: 0,
          export: 0,
          import: 0,
          delete: 0,
          print: 0,
        },
        {
          sub_module_id: '64d5f124cb27940571a70bdc',
          name: 'Mobile App Price List',
          create: 0,
          edit: 0,
          view: 0,
          export: 0,
          import: 0,
          delete: 0,
          print: 0,
        },
      ],
    },
    {
      isSelectedAll: 0,
      main_module_id: '649450b7774cc1ac05c03ea0',
      name: 'Purchases',
      is_active: true,
      sub_module_permission: [
        {
          sub_module_id: '64945235774cc1ac05c03eee',
          name: 'Purchase Order',
          create: 0,
          edit: 0,
          view: 0,
          export: 0,
          import: 0,
          delete: 0,
          print: 0,
        },
        {
          sub_module_id: '64945231774cc1ac05c03eea',
          name: 'Purchase Dashboard',
          create: 0,
          edit: 0,
          view: 0,
          export: 0,
          import: 0,
          delete: 0,
          print: 0,
        },
        {
          sub_module_id: '651277f41242f9271909e863',
          name: 'PP Price History',
          create: 0,
          edit: 0,
          view: 0,
          export: 0,
          import: 0,
          delete: 0,
          print: 0,
        },
        {
          sub_module_id: '655c562e7d52b34ec408986a',
          name: 'NL Requirement',
          create: 0,
          edit: 0,
          view: 0,
          export: 0,
          import: 0,
          delete: 0,
          print: 0,
        },
        {
          sub_module_id: '6494523b774cc1ac05c03ef2',
          name: 'Purchase Entry',
          create: 0,
          edit: 0,
          view: 0,
          export: 0,
          import: 0,
          delete: 0,
          print: 0,
        },
        {
          sub_module_id: '64945250774cc1ac05c03ef6',
          name: 'Pre-Printed P.O. Status',
          create: 0,
          edit: 0,
          view: 0,
          export: 0,
          import: 0,
          delete: 0,
          print: 0,
        },
      ],
    },
    {
      isSelectedAll: 0,
      main_module_id: '649450bd774cc1ac05c03ea4',
      name: 'Sales',
      is_active: true,
      sub_module_permission: [
        {
          sub_module_id: '64945268774cc1ac05c03efe',
          name: 'Orders',
          create: 0,
          edit: 0,
          view: 0,
          export: 0,
          import: 0,
          delete: 0,
          print: 0,
        },
        {
          sub_module_id: '64945277774cc1ac05c03f02',
          name: 'Pro-forma Invoice',
          create: 0,
          edit: 0,
          view: 0,
          export: 0,
          import: 0,
          delete: 0,
          print: 0,
        },
        {
          sub_module_id: '6494527d774cc1ac05c03f06',
          name: 'Tax Invoice',
          create: 0,
          edit: 0,
          view: 0,
          export: 0,
          import: 0,
          delete: 0,
          print: 0,
        },
        {
          sub_module_id: '6593d927d791c357fbecbc34',
          name: 'Order Operator',
          create: 0,
          edit: 0,
          view: 0,
          export: 0,
          import: 0,
          delete: 0,
          print: 0,
        },
      ],
    },
    {
      isSelectedAll: 0,
      main_module_id: '649450c4774cc1ac05c03ea8',
      name: 'Production',
      is_active: true,
      sub_module_permission: [
        {
          sub_module_id: '64945298774cc1ac05c03f0a',
          name: 'MFG Live',
          create: 0,
          edit: 0,
          view: 0,
          export: 0,
          import: 0,
          delete: 0,
          print: 0,
        },
        {
          sub_module_id: '649452a4774cc1ac05c03f0e',
          name: 'Machine Job Queue',
          create: 0,
          edit: 0,
          view: 0,
          export: 0,
          import: 0,
          delete: 0,
          print: 0,
        },
      ],
    },
    {
      isSelectedAll: 0,
      main_module_id: '649450cc774cc1ac05c03eac',
      name: 'Financials',
      is_active: true,
      sub_module_permission: [
        {
          sub_module_id: '649452bc774cc1ac05c03f12',
          name: 'Profit Analysis',
          create: 0,
          edit: 0,
          view: 0,
          export: 0,
          import: 0,
          delete: 0,
          print: 0,
        },
        {
          sub_module_id: '649452c6774cc1ac05c03f16',
          name: 'Collection SMS',
          create: 0,
          edit: 0,
          view: 0,
          export: 0,
          import: 0,
          delete: 0,
          print: 0,
        },
        {
          sub_module_id: '649452cd774cc1ac05c03f1a',
          name: 'Broker margin',
          create: 0,
          edit: 0,
          view: 0,
          export: 0,
          import: 0,
          delete: 0,
          print: 0,
        },
      ],
    },
    {
      isSelectedAll: 0,
      main_module_id: '649450d7774cc1ac05c03eb4',
      name: 'Settings',
      is_active: true,
      sub_module_permission: [
        {
          _id: '649452eb774cc1ac05c03f1e',
          name: 'Company',
          create: 0,
          edit: 0,
          view: 0,
          export: 0,
          import: 0,
          delete: 0,
          print: 0,
        },
        {
          _id: '649452ef774cc1ac05c03f22',
          name: 'Users',
          create: 0,
          edit: 0,
          view: 0,
          export: 0,
          import: 0,
          delete: 0,
          print: 0,
        },
        {
          _id: '649452fb774cc1ac05c03f26',
          name: 'Items(Raw Materials)',
          create: 0,
          edit: 0,
          view: 0,
          export: 0,
          import: 0,
          delete: 0,
          print: 0,
        },
        {
          _id: '64945305774cc1ac05c03f2a',
          name: 'Misc Masters',
          create: 0,
          edit: 0,
          view: 0,
          export: 0,
          import: 0,
          delete: 0,
          print: 0,
        },
        {
          _id: '6494530e774cc1ac05c03f2e',
          name: 'Roles & Permissions',
          create: 0,
          edit: 0,
          view: 0,
          export: 0,
          import: 0,
          delete: 0,
          print: 0,
        },
        {
          _id: '64d5f18acb27940571a70bdf',
          name: 'General Configuration',
          create: 0,
          edit: 0,
          view: 0,
          export: 0,
          import: 0,
          delete: 0,
          print: 0,
        },
        {
          _id: '64945315774cc1ac05c03f32',
          name: 'Other',
          create: 0,
          edit: 0,
          view: 0,
          export: 0,
          import: 0,
          delete: 0,
          print: 0,
        },
      ],
    },
    {
      isSelectedAll: 0,
      main_module_id: '',
      name: 'Report',
      is_active: true,
      sub_module_permission: [
        {
          sub_module_id: '',
          name: 'Raw Material',
          create: 0,
          edit: 0,
          view: 0,
          export: 0,
          import: 0,
          delete: 0,
          print: 0,
        },
        {
          sub_module_id: '',
          name: 'Finished Goods',
          create: 0,
          edit: 0,
          view: 0,
          export: 0,
          import: 0,
          delete: 0,
          print: 0,
        },
        {
          sub_module_id: '',
          name: 'Pending Jobs',
          create: 0,
          edit: 0,
          view: 0,
          export: 0,
          import: 0,
          delete: 0,
          print: 0,
        },
        {
          sub_module_id: '',
          name: 'Finance',
          create: 0,
          edit: 0,
          view: 0,
          export: 0,
          import: 0,
          delete: 0,
          print: 0,
        },
        {
          sub_module_id: '',
          name: 'Sales',
          create: 0,
          edit: 0,
          view: 0,
          export: 0,
          import: 0,
          delete: 0,
          print: 0,
        },
        {
          sub_module_id: '',
          name: 'Purchase',
          create: 0,
          edit: 0,
          view: 0,
          export: 0,
          import: 0,
          delete: 0,
          print: 0,
        },
        {
          sub_module_id: '',
          name: 'Monthly Turnover',
          create: 0,
          edit: 0,
          view: 0,
          export: 0,
          import: 0,
          delete: 0,
          print: 0,
        },
      ],
    },
  ],
};

export let companyAddress = {
  company_id: '',
  address_type: '64994f3950bb0ca87d860c2a',
  address_type_name: '',
  location: '',
  address: '',
  pincode: '',
  city: '',
  city_name: '',
  state: '',
  state_name: '',
  country: '',
  country_name: '',
  no: 0,
};

let initialState = {
  loading: false,
  settingsCRUDLoading: false,
  userRoles: undefined,
  allModules: [],
  userPermissionList: [],
  operatorpermissionList: [],
  businessTypeList: [],
  activeFactoryLocationList: [],
  activeOperatorRole: [],
  activeBagMachineList: [],
  companyDetails: {
    company_name: '',
    legal_name: '',
    business_type: '',
    website: '',
    mobile_no: '',
    email_id: '',
    director_name: '',
    pan_no: '',
    tan_no: '',
    gst_no: '',
    company_address: [],
    city: '',
    state: '',
    country: '',
    pincode: '',
    company_logo: null,
    is_active: true,
    business_type_name: '',
  },
  activeUnitList: [],
  activeItemGroupList: [],
  selectedRolePermission: permission,
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setSettingLoading: (state, action) => {
      state.loading = action.payload;
    },
    setSettingsCRUDLoading: (state, action) => {
      state.settingsCRUDLoading = action.payload;
    },
    setUserRoles: (state, action) => {
      state.userRoles = action.payload;
    },
    setAllModules: (state, action) => {
      state.allModules = action.payload;
    },
    setUserpermissionList: (state, action) => {
      state.userPermissionList = action.payload;
    },
    setOperatorpermissionList: (state, action) => {
      state.operatorpermissionList = action.payload;
    },
    setBusinessTypeList: (state, action) => {
      state.businessTypeList = action.payload;
    },
    setCompanyDetails: (state, action) => {
      state.companyDetails = action.payload;
    },
    setActiveFactoryLocationList: (state, action) => {
      state.activeFactoryLocationList = action.payload;
    },
    setActiveOperatorRoleList: (state, action) => {
      state.activeOperatorRole = action.payload;
    },
    setActiveBagMachineList: (state, action) => {
      state.activeBagMachineList = action.payload;
    },
    setActiveUnitList: (state, action) => {
      state.activeUnitList = action.payload;
    },
    setActiveItemGroupList: (state, action) => {
      state.activeItemGroupList = action.payload;
    },
    clearSelectedRolePermissions: state => {
      state.selectedRolePermission = initialState.selectedRolePermission;
    },
    setSelectedRolePermissions: (state, action) => {
      state.selectedRolePermission = action.payload;
    },
  },
});

export const {
  setSettingLoading,
  setSettingsCRUDLoading,
  setUserRoles,
  setAllModules,
  setUserpermissionList,
  setOperatorpermissionList,
  setBusinessTypeList,
  setCompanyDetails,
  setActiveFactoryLocationList,
  setActiveOperatorRoleList,
  setActiveBagMachineList,
  setActiveUnitList,
  setActiveItemGroupList,
  clearSelectedRolePermissions,
  setSelectedRolePermissions,
} = settingsSlice.actions;

export default settingsSlice.reducer;
